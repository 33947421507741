<template>
  <div>
    <div v-if="selectUser == ''">
      <el-button type="primary" @click="dialogVisible = true" :disabled="disabled"
        >选择用户</el-button
      >
    </div>
    <div v-else>
      <el-table :data="selectUser" style="width: 100%">
        <el-table-column prop="id" label="id" width="80"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="address" label="头像">
          <template slot-scope="scope">
            <el-avatar :src="scope.row.avatar_url"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="danger" @click="addUser(scope.row)"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="用户"
      :visible.sync="dialogVisible"
      append-to-body
      width="50%"
    >
      <div class="flex selectUser">
        <el-select v-model="selectSearch" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="userInfo"
          placeholder="请输入内容"
          style="width: 300px"
        ></el-input>

        <el-button type="primary" @click="search">搜索</el-button>
      </div>
      <div>
        <el-table v-loading="loading" :data="userList" style="width: 100%">
          <el-table-column prop="id" label="id" width="80"> </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="address" label="头像">
            <template slot-scope="scope">
              <el-avatar :src="scope.row.avatar_url"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" @click="addUser(scope.row)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="selectUser" style="width: 100%">
          <el-table-column prop="id" label="id" width="80"> </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="address" label="头像">
            <template slot-scope="scope">
              <el-avatar :src="scope.row.avatar_url"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button type="danger" @click="deleteUser(scope.row)"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
          <el-button type="primary" @click="submit"
            >确定</el-button
          >
          <el-button
            @click="
              selectUser = [];
              dialogVisible = false;
            "
            >取消</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props:{
    disabled:{
      default:false,
      type:Boolean
    }
  },
  data() {
    
    return {
      dialogVisible: false,
      loading: false,
      userList: [],
      userInfo: "",
      selectSearch: 1,
      options: [
        {
          value: 1,
          label: "用户UID",
        },
        {
          value: 2,
          label: "昵称",
        },
      ],
      selectUser: [],
    };
  },
  methods: {
    ...mapActions("user", ["selectStudents"]),
    submit(){
        this.$emit("userList",this.userList)
        this.dialogVisible = false
    },
    deleteUser(row) {
      let index = this.selectUser.findIndex((v) => v.id == row.id);
      if (index != -1) {
        this.selectUser.splice(index, 1);
      }
    },
    addUser(row) {
      row.state = true;
      let index = this.selectUser.findIndex((v) => v.id == row.id);
      if (index == -1) {
        this.selectUser.push(row);
      }
    },
    async search() {
      this.loading = true;
      let form = {};
      if (this.selectSearch == 1) form.userId = this.userInfo;
      else form.nickName = this.userInfo;
      const { data } = await this.selectStudents(form);

      this.userList = data;
      this.loading = false;
      
    },
  },
};
</script>
<style scoped>
.selectUser {
  display: flex;
  justify-content: space-evenly;
}
</style>
>
