<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>用户</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/userMessage' }"
          >消息通知</el-breadcrumb-item
        >
        <el-breadcrumb-item>发布消息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="mini"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="form.title"
            style="width: 700px"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="微信消息通知" prop="brief" v-if="form.isPush">
          <el-input
            v-model="form.brief"
            style="width: 700px"
            type="textarea"
            maxlength="150"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="是否推送">
          <el-radio v-model="form.isPush" :label="true">是</el-radio>
          <el-radio v-model="form.isPush" :label="false">否</el-radio>
        </el-form-item>
        <el-form-item label="是否全部">
          <el-radio v-model="form.isAll" :label="true">是</el-radio>
          <el-radio v-model="form.isAll" :label="false">否</el-radio>
        </el-form-item>
        <el-form-item label="是否全部" v-if="form.isAll == false">
          <div style="width: 60%">
            <select-Normal-User @userList="getUser"></select-Normal-User>
          </div>
        </el-form-item>
        <el-form-item label="详情" prop="detail">
          <editor @onChange="onChange" :content="form.detail"></editor>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            :loading="loading"
            @click="onSubmit"
            >保存</el-button
          >
          <router-link to="/goods" class="router-link">
            <el-button style="margin-left: 10px" size="small">取消</el-button>
          </router-link>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import editor from "../../../components/editor";
import selectNormalUser from "../../../components/selectNormalUser";
export default {
  name: "template-add",
  components: {
    editor,
    selectNormalUser,
  },
  data() {
    return {
      loading: false,
      form: {
        isPush: true,
        isAll: false,
        path: "pages/index/index",
      },

      rules: {
        brief: [
          { required: true, message: "请输入通知", trigger: "blur" },
          { max: 150, message: "长度在 3 到 5 个字符以内", trigger: "blur" },
        ],
        detail: [{ required: true, message: "请输入详情", trigger: "blur" }],
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {  max: 100, message: "长度在 100 个字符以内", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    ...mapActions("user", ["publish"]),
    getUser(e) {
      let arr = [];
      e.forEach((v) => {
        arr.push(v.id);
      });
      this.form.userIds = arr;
    },
    onChange(content) {
      this.form.detail = content;
    },
    async onSubmit() {
      let _this = this;
      this.loading = true;
      console.log(this.form);

      if (!this.validateForm("form")) {
        this.$message.error("请填写必填选项！");
        return false;
      }
      const { res_info } = await this.publish(this.form);

      this.loading = false;
      if (res_info != "ok") return;
      this.$router.go(-1);
      this.$message.success("发布成功");
    },

    validateForm(formName) {
      let validate = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          validate = true;
          return true;
        } else {
          validate = false;
          return false;
        }
      });
      return validate;
    },
  },
};
</script>

<style scoped></style>
